<template>
  <div class="goto-statistics" :class="{ 'goto-statistics--mobile': isMobile }" @click="$emit('click')">
    <div class="goto-statistics__text">Ver estadísticas</div>
    <div class="goto-statistics__icon">
      <img src="/icons/icn_statistics.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'GoToStatisticsButton',
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.goto-statistics {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: #00a5ff;
  color: white;
  padding: 0.6rem 1rem 0.5rem;
  cursor: pointer;

  &__text {
    margin-right: 0.6rem;
  }

  &__icon {
    height: 1.5rem;
    width: 1.5rem;
  }

  &--mobile {
    border-radius: 8px;
    padding: 0.5rem 1rem 0.4rem;

    & div.goto-statistics__icon {
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  @media screen and (max-width: 380px) {
    padding: 0.6rem 2.5rem 0.5rem;

    &__text {
      display: none;
    }
  }
}
</style>
